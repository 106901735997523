import { Button, Card, Typography, mq } from '@ensdomains/thorin';
import { useParams, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const StyledCard = styled(Card)(
  ({ theme }) => css`
    max-width: 780px;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: ${theme.space['4']};
    padding: ${theme.space['4']};
    canvas {
      max-width: ${theme.space.full};
    }

    ${mq.sm.min(css`
      padding: ${theme.space['6']} ${theme.space['18']};
      gap: ${theme.space['6']};
    `)}
  `
);

const ButtonContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space.full};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['2']};
  `
);

const NFTContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space['56']};
    height: ${theme.space['56']};
    border-radius: ${theme.radii['2xLarge']};
    overflow: hidden;

    ${mq.sm.min(css`
      width: ${theme.space['80']};
      height: ${theme.space['80']};
    `)}
  `
);

const TitleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['2']};
  `
);

const Title = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.headingOne};
    font-weight: 700;
    line-height: ${theme.lineHeights.headingOne};
  `
);

const SubtitleWithGradient = styled(Typography)(
  ({ theme }) => css`
    display: inline;

    font-size: ${theme.fontSizes.headingThree};
    font-weight: bold;

    background-image: ${theme.colors.gradients.blue};
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    /* stylelint-enable property-no-vendor-prefix */

    b {
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      color: transparent;
      line-height: 100%;
    }
  `
);

const MobileFullWidth = styled.div(
  ({ theme }) => css`
    & > div,
    & {
      width: ${theme.space.full};
      max-width: ${theme.space.full};
      ${mq.sm.min(css`
        min-width: ${theme.space['40']};
        width: fit-content;
        max-width: max-content;
      `)}
    }
  `
);

const Container = styled.div(
  ({ theme }) => css`
    padding: ${theme.space['4']};
    background: ${theme.colors.backgroundSecondary};
    display: flex;
    flex-direction: column;
    gap: ${theme.space['2']};
    width: 100%;
    border-radius: ${theme.space['2']};
  `
);

const LineItem = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    line-height: ${theme.space['5']};
    color: ${theme.colors.textTertiary};
  `
);

const items = [
  {
    label: 'Registration',
    value: '5 EDX',
    bufferPercentage: '5',
    color: 'primary',
  },
  {
    label: 'Network fee',
    value: '0.0010 EDX',
    bufferPercentage: '10',
    color: 'secondary',
  },
  {
    label: 'Total paid',
    value: '0.0010 EDX',
    bufferPercentage: '10',
    color: 'secondary',
  },
];

const SvgCard = ({ name }: any) => {
  return (
    <svg
      viewBox="0 0 270 270"
      display="block"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="url(#paint0_linear)" width="270" height="270"></rect>
      <path
        d="M110.907 32.9539H87.744L57.0666 75.6949L87.5627 118.321H110.726L80.2868 75.6949L110.907 32.9539Z"
        fill="white"
        filter="url(#dropShadow)"
      />
      <path
        d="M58.718 45.5889L49.6094 32.9539H26.4466L47.2762 61.9207L58.718 45.5889Z"
        fill="white"
        filter="url(#dropShadow)"
      />
      <path
        d="M26.6739 118.318H49.8367L60.0816 104.035L48.1853 88.2695L26.6739 118.318Z"
        fill="white"
        filter="url(#dropShadow)"
      />
      <text
        x="20.5"
        y="231"
        opacity="1"
        fill="white"
        filter="url(#dropShadow)"
        className="sc-91b0062b-0 eEbIvs"
        style={{ fontSize: '33px', fontWeight: '700' }}
      >
        {name}
      </text>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="190.5"
          y1="302"
          x2="-64"
          y2="-172.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44BCF0"></stop>
          <stop offset="0.428185" stopColor="#628BF3"></stop>
          <stop offset="1" stopColor="#A099FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="0"
          y1="0"
          x2="269.553"
          y2="285.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB9E9E"></stop>
          <stop offset="1" stopColor="#992222"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

const SuccessPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <StyledCard>
      <NFTContainer>
        <SvgCard name={params?.id} />
      </NFTContainer>
      <TitleContainer>
        <Title>Congratulations!</Title>
        <Typography
          style={{ display: 'inline' }}
          fontVariant="headingThree"
          weight="bold"
        >
          You are now the owner of{' '}
          <SubtitleWithGradient>{params?.id}</SubtitleWithGradient>
          <br></br>
          <br></br>
        </Typography>
      </TitleContainer>
      <Typography>
        Your name was successfully registered. You can now view and manage your
        name.
      </Typography>

      <br></br>
      <br></br>
      {/* 
     <Container>
        {items.map(({ label, value, bufferPercentage, color }, inx) => (
          <LineItem data-testid={`invoice-item-${inx}`} key={label}>
            <div>{label}</div>
            <Skeleton loading={!value}>
              <div data-testid={`invoice-item-${inx}-amount`}>{value}</div>
            </Skeleton>
          </LineItem>
        ))}
      </Container> */}

      <ButtonContainer>
        <MobileFullWidth>
          <Button colorStyle="accentSecondary" onClick={() => navigate('/')}>
            Register another
          </Button>
        </MobileFullWidth>
        <MobileFullWidth>
          <Button
            data-testid="view-name"
            onClick={() => navigate(`/${params?.id}/profile`)}
          >
            View name
          </Button>
        </MobileFullWidth>
      </ButtonContainer>
    </StyledCard>
  );
};

export default SuccessPage;
